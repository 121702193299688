import {
  fontSize,
  grid,
  maxWidthContainer,
  minBreakpoints,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing, text } = theme;

export const linksBanner = css`
  ${grid}
  ${maxWidthContainer}
  grid-row-gap: 0;
`;

export const bottomOffsetImageMarginLinksBanner = css`
  padding-top: ${pxToRem(spacing.xxl)};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      padding-top: ${pxToRem(spacing.xl)};
    `,
  )};
`;

export const imageWrapper = css`
  grid-column: span 12;
  margin-bottom: ${pxToRem(spacing.sm)};

  ${mediaQuery(
    { min: minBreakpoints.XS },
    css`
      grid-column: 1 / span 6;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.S },
    css`
      grid-column: 1 / span 4;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: 1 / span 3;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.XL },
    css`
      grid-column: 2 / span 3;
    `,
  )};
`;

export const subHeading = css`
  margin: auto 0;
  padding-bottom: ${pxToRem(spacing.lg)};
  font-weight: normal;
  ${fontSize(text.BODY.SM)}

  ${mediaQuery(
    { min: minBreakpoints.XXS },
    css`
      grid-column: span 12;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.XS },
    css`
      grid-column: span 6;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.S },
    css`
      grid-column: span 8;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: span 9;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: none;
    `,
  )};
`;

export const content = css`
  margin: -${pxToRem(spacing.sm)} 0 ${pxToRem(spacing.base)};
  grid-column: span 12;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: 1 / span 12;
      margin: 0;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 4 / span 9;
      margin: ${pxToRem(spacing.xxl)} 0;
    `,
  )};

  ${mediaQuery(
    { min: minBreakpoints.XL },
    css`
      grid-column: 6 / span 6;
    `,
  )};
`;
