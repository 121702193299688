import type { CSSProperties } from 'react';
import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing, text } = theme;

export interface CSSRowProperty extends CSSProperties {
  '--rows'?: number;
}

export const list = css`
  list-style: none;
  grid-auto-flow: column;
  margin: 0 0 ${pxToRem(20)} 0;
  padding: 0;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      display: grid;
      grid-column-gap: ${pxToRem(40)};
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(var(--rows), 1fr);
    `,
  )};
`;

export const item = css`
  margin: 0 0 ${pxToRem(spacing.sm)} 0;
`;

export const link = css`
  ${fontSize(text.BODY.MD)}
  color: var(--brand-purple-base-high-contrast);
  text-decoration: underline;
  font-weight: normal;
`;
