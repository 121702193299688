import {
  fontSize,
  maxBreakpoints,
  minBreakpoints,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const linksBlock = css`
  margin-bottom: ${pxToRem(spacing.lg)};
  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: block;
    `,
  )};
`;

export const title = css`
  text-align: center;
  ${fontSize(text.HEADING.SM)}
`;

export const subHeading = css`
  margin: ${pxToRem(spacing.lg)} 0;
  text-align: center;
  font-weight: ${fonts.PRIMARY_REGULAR.weight};
  ${fontSize(text.HEADING.XS)}

  ${mediaQuery(
    { max: maxBreakpoints.M },
    css`
      display: none;
    `,
  )}
`;
