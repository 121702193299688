import { fontSize, theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const heading = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${pxToRem(20)} 0 ${pxToRem(20)};
  margin: 0;
  border-top: 1px solid var(--neutral-black-30);
`;

export const title = css`
  ${fontSize(text.HEADING.XS)}
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: 600;
  color: var(--neutral-black-base);
`;

export const icon = css`
  width: ${pxToRem(spacing.md)};
  height: ${pxToRem(spacing.md)};
`;
