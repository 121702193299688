import React from 'react';

import { LinksBlock } from 'src/components/molecules/LinksBlock/LinksBlock';
import { LinksBlockGroup } from 'src/components/molecules/LinksBlock/LinksBlockGroup';
import type { LinkGroup } from 'src/components/molecules/LinksBlock/types';
import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import { trackImpression } from 'src/tracking/common/trackImpression';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './LinksBlockSection.style';

export interface LinksBlockSectionProps {
  backgroundColor: string;
  imageAlt: string;
  imageUrl: string;
  linkGroups: LinkGroup[];
  subHeading: string;
  title: string;
  trackingPosition?: number;
  trackingTitle: string;
}

export const LinksBlockSection = ({
  backgroundColor,
  imageAlt,
  imageUrl,
  linkGroups,
  subHeading,
  title,
  trackingPosition,
  trackingTitle,
}: LinksBlockSectionProps) => {
  const trackingContext: TrackingContext = {
    trackingPosition,
    trackingTitle,
    trackingContentType: 'Links block',
  };

  return (
    <SectionContainer maxWidth="full" wrapperStyles={styles.wrapper}>
      <LinksBlock
        backgroundColor={backgroundColor}
        imageAlt={imageAlt}
        imageUrl={imageUrl}
        onVisible={() => trackImpression(trackingContext)}
        subHeading={subHeading}
        title={title}
      >
        {linkGroups.map(({ links, open, title }) => (
          <LinksBlockGroup key={title} links={links} open={open} title={title} />
        ))}
      </LinksBlock>
    </SectionContainer>
  );
};
