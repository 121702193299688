import type { ReactNode } from 'react';
import React from 'react';
import { useVisibilityObserver } from '@noths/polaris-client-react-hooks';
import { Heading } from '@noths/polaris-client-ribbons-design-system';

import { LinksBanner } from './LinksBanner';
import * as styles from './LinksBlock.styles';

export interface LinksBlockProps {
  backgroundColor: string;
  children: ReactNode;
  imageAlt: string;
  imageUrl: string;
  onVisible?: () => void;
  subHeading: string;
  title: string;
}

export const LinksBlock = ({
  backgroundColor,
  children,
  imageAlt,
  imageUrl,
  onVisible = () => {},
  subHeading,
  title,
}: LinksBlockProps) => {
  const { observedElementRef } = useVisibilityObserver(() => onVisible());
  return (
    <div ref={observedElementRef}>
      <Heading css={styles.title} level={2}>
        {title}
      </Heading>
      <p css={styles.subHeading}>{subHeading}</p>
      <LinksBanner
        backgroundColor={backgroundColor}
        imageAlt={imageAlt}
        imageUrl={imageUrl}
        subHeading={subHeading}
      >
        <div css={styles.linksBlock}>{children}</div>
      </LinksBanner>
    </div>
  );
};
