import React from 'react';

import type { Link } from './types';
import * as styles from './LinksList.styles';

interface LinksListProps {
  links: Link[];
}

export const LinksList = ({ links }: LinksListProps) => {
  const rows = Math.ceil(links.length / 3);
  return (
    <ul css={styles.list} style={{ '--rows': rows } as styles.CSSRowProperty}>
      {links.map((link) => (
        <li css={styles.item} key={link.text}>
          <a css={styles.link} href={link.href}>
            {link.text}
          </a>
        </li>
      ))}
    </ul>
  );
};
