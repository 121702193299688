import React, { createRef, useState } from 'react';

import { LinksHeading } from './LinksHeading';
import { LinksList } from './LinksList';
import type { Link } from './types';
import * as styles from './LinksBlockGroup.styles';

export interface LinksBlockGroupProps {
  links: Link[];
  open?: boolean;
  title: string;
}

export const LinksBlockGroup = ({ links, open = false, title }: LinksBlockGroupProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const headingRef = createRef<HTMLElement>();

  const toggleIsOpen = () => (isOpen ? setIsOpen(false) : setIsOpen(true));

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    const keyCode = e.key;

    if (['Enter', ' ', 'Spacebar'].includes(keyCode)) {
      toggleIsOpen();
    }
  };

  return (
    <details css={styles.item} {...(open && { open })}>
      <summary
        aria-expanded={isOpen}
        css={styles.heading}
        onClick={toggleIsOpen}
        onKeyDown={handleKeyPress}
        ref={headingRef}
      >
        <LinksHeading open={isOpen} title={title} />
      </summary>
      <div style={{ display: 'block' }}>
        <LinksList links={links} />
      </div>
    </details>
  );
};
