import { css } from 'styled-components';

export const heading = css`
  display: block;
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
`;

export const item = css`
  display: block;
  &:last-of-type {
    border-bottom: 1px solid var(--neutral-black-30);
  }
`;
