import type { ReactElement } from 'react';
import React from 'react';
import { Banner, FloatingBannerImage } from '@noths/polaris-client-ribbons-design-system';

import * as styles from './LinksBanner.styles';

export interface LinksBannerProps {
  backgroundColor: string;
  children: ReactElement;
  imageAlt: string;
  imageUrl: string;
  subHeading?: string;
}

export const LinksBanner = ({
  backgroundColor,
  children,
  imageAlt,
  imageUrl,
  subHeading,
}: LinksBannerProps) => {
  const defaultImageHeight = 300;
  const defaultImageWidth = 300;

  return (
    <div css={styles.bottomOffsetImageMarginLinksBanner}>
      <Banner backgroundColor={backgroundColor} roundedCorners>
        <div css={styles.linksBanner}>
          <div css={styles.imageWrapper}>
            <FloatingBannerImage
              imageAlt={imageAlt}
              imageAspectRatio="1 / 1"
              imageHeight={defaultImageHeight}
              imageWidth={defaultImageWidth}
              images={[{ src: imageUrl, width: defaultImageWidth }]}
              offset="bottom"
            />
          </div>
          {subHeading && <p css={styles.subHeading}>{subHeading}</p>}
          <div css={styles.content}>{children}</div>
        </div>
      </Banner>
    </div>
  );
};
