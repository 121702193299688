import React from 'react';
import { IconChevronDown, IconChevronUp } from '@noths/polaris-client-ribbons-design-system';

import * as styles from './LinksHeading.styles';

interface LinksHeadingProps {
  open?: boolean;
  title: string;
}

export const LinksHeading = ({ open, title }: LinksHeadingProps) => {
  return (
    <h3 css={styles.heading}>
      <span css={styles.title}>{title}</span>
      {open ? <IconChevronUp css={styles.icon} /> : <IconChevronDown css={styles.icon} />}
    </h3>
  );
};
